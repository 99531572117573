import { ngxLoadingAnimationTypes } from "ngx-loading"

const primaryColour = '#ffffff'
const secondaryColour = '#CCCCCC'
// const PrimaryRed = '#dd0031';
// const SecondaryBlue = '#85b2f9';

export const PAGINATION_CONFIG = {
    
}

export const LOADER_CONFIG = {
    animationType: ngxLoadingAnimationTypes.wanderingCubes,
    primaryColour: primaryColour,
    secondaryColour: secondaryColour,
    tertiaryColour: primaryColour,
    backdropBorderRadius: '0px',
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.5)'
}

export const TOASTR_CONFIG = {
    timeOut: 2000,
    progressBar: true
}

export let adminReportCSVOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    useBom: true,
    noDownload: false,
    headers: [
        'ID',
        'Site',
        'Wearer',
        'Alert Condition',
        'Alert Status',
        'Alert Type',
        'Alert Value/Code',
        'Alert Creation Date',
        'Device Id',
        'Date/Time',
        'Updated At'
    ]
}

export const timeSliceCSVOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalseparator: ".",
    showLabels: true,
    useBom: true,
    noDownload: false,
    headers: [
        'ID',
        'Battery %',
        'Shin Unit Battery %',
        'Device ID',
        'CH 1 ZONE 1','CH 1 ZONE 2','CH 1 ZONE 3','CH 1 ZONE 4','CH 1 ZONE 5',
        'CH 2 ZONE 1','CH 2 ZONE 2','CH 2 ZONE 3','CH 2 ZONE 4','CH 2 ZONE 5',
        'CH 3 ZONE 1','CH 3 ZONE 2','CH 3 ZONE 3','CH 3 ZONE 4','CH 3 ZONE 5',
        'CH 4 ZONE 1','CH 4 ZONE 2','CH 4 ZONE 3','CH 4 ZONE 4','CH 4 ZONE 5',
        'CH 5 ZONE 1','CH 5 ZONE 2','CH 5 ZONE 3','CH 5 ZONE 4','CH 5 ZONE 5',
        'Step Count',
        'Shin Unit Operation Time',
        'Temperature(°C)',
        'Humidity(%)',
        'Site Id',
        'Site Name',
        'Wearer Id',
        'Wearer Name',
        'Timeslice Interval',
        'Timeslice Start',
        'Timeslice End'
    ]
}

export const alertTypes: Array<any> = [
    {
        type: 'Activity Alerts',
        count: 0,
        accId: 'la', 
        target: '#la'
    },
    {
        type: 'Device Sync Alerts',
        count: 0,
        accId: 'ds', 
        target: '#ds'
    }, 
    {
        type: 'Low Battery Alerts',
        count: 0,
        accId: 'lb', 
        target: '#lb'
    },
    {
        type: 'Fault Alerts',
        count: 0,
        accId: 'fa',
        target: '#fa'
    } 
]

export const timeSlots: Array<any> = [
    {
        time: '12am - 6am'
    }, 
    {
        time: '6am - 12am'
    }, 
    {
        time: '12pm - 6pm'
    }, 
    {
        time: '6pm - 12am'
    }, 
    {
        time: 'All Day'
    }
]
